import React from "react"
import { graphql, PageProps } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"
import { CalculatorSelectContext } from "@containers/CalculatorSelect"
import CalculatorSelectCountryContainer from "@containers/CalculatorSelect/CalculatorSelectCountryContainer"
import LayoutContainer from "@containers/Layout"
import SEO from "@components/SEO"
import Hero from "@components/HomePartner/HeroSection"
import About from "@components/HomePartner/AboutSection"
import CalculatorSection from "@components/HomePartner/CalculatorSection"
import Cards from "@components/HomePartner/CardsSection"
import Slider from "@components/HomePartner/SliderSection"
import BottomHero from "@components/HomePartner/BottomSection"
import { HomePartnersDataType } from "@typings/requests/partners/partners-home"
import "./index.scss"

const HomePartnersPage: React.FC<PageProps<HomePartnersDataType>> = props => {
  const {
    allStrapiHomePage: {
      nodes: [indexPartnerData],
    },
    allStrapiPageFooter: {
      nodes: [footerData],
    },
  } = props.data
  const { topHero, about, calculator, cards, cases, bottomHero } =
    indexPartnerData

  return (
    <>
      <SEO
        title={indexPartnerData.title}
        description={indexPartnerData.description}
        pathname={props.location.pathname}
        lang={indexPartnerData.locale}
        image={indexPartnerData.metaImage.url}
      />
      <ParallaxProvider>
        <LayoutContainer
          className="partners-home"
          location={props.location}
          lang="es"
          isPartnerSubdomain
        >
          <Hero
            {...topHero}
            firstImage={
              topHero.firstImage.localFile.childImageSharp.gatsbyImageData
            }
            secondImage={
              topHero.secondImage.localFile.childImageSharp.gatsbyImageData
            }
          />
          <About
            {...about}
            text={about.text.data.text}
            image={about.image.localFile.childImageSharp.gatsbyImageData}
          />
          <CalculatorSelectContext {...calculator}>
            <CalculatorSection
              calculatorSelectCountryComponent={
                <CalculatorSelectCountryContainer />
              }
            />
          </CalculatorSelectContext>
          <Cards {...cards} />
          <Slider {...cases} />
          <BottomHero
            {...bottomHero}
            image={bottomHero.image.localFile.childImageSharp.gatsbyImageData}
            footerData={footerData}
          />
        </LayoutContainer>
      </ParallaxProvider>
    </>
  )
}

export const query = graphql`
  {
    allStrapiHomePage(filter: { locale: { eq: "es" } }) {
      nodes {
        locale
        metaImage {
          url
        }
        title
        description
        about {
          description
          id
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 472
                  placeholder: BLURRED
                  layout: CONSTRAINED
                )
              }
            }
          }
          text {
            data {
              text
            }
          }
          title
        }
        bottomHero {
          title
          buttonLink: button {
            label
            url
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 560
                  quality: 95
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        calculator {
          button {
            label
            url
          }
          costHeader
          ctaTitle
          description
          id
          inputLabel
          title
        }
        cards {
          title
          description
          cardsItems {
            ctaLink
            ctaTitle
            id
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 574, quality: 100, layout: CONSTRAINED)
                }
              }
            }
          }
        }
        cases {
          description
          title
          CasesItem {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    width: 940
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            link
            tags
            title
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 40, height: 40, layout: FIXED)
                }
              }
            }
          }
        }
        topHero {
          description
          title
          buttonLink: button {
            url
            label
          }
          firstImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 378
                  quality: 90
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
          }
          secondImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 378
                  quality: 90
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
    allStrapiPageFooter(filter: { locale: { eq: "es" } }) {
      nodes {
        title
        socialLink {
          name
          title
          url
        }
      }
    }
  }
`

export default HomePartnersPage
